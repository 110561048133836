import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import BreadCrumb from "../BreadCrumb";
import * as Style from "./products.module.css";

const Desktop = ({ name }) => {
  const [openTab, setOpenTab] = useState(2);
  const selectedTabBackground = " bg-[#eae2d8]";
  const tabBackground = " bg-transparent";
  return (
    <div className="md:hidden bg-heading-color pt-5">
      <div className="mt-12" />
      <div className="container m-auto px-5 pb-12">
        <BreadCrumb>home/products/{name}</BreadCrumb>
        <div className="mb-16" />
        {/* Carousel */}
        <div className={`grid grid-cols-2 py-4 h-[37rem] relative`}>
          <div className="flex flex-col justify-between w-4/5 h-[35rem]">
            <div className="flex w-3/5">
              <svg
                className="h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  color="#E0B65F"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              <h2 className="flex-1 uppercase text-custom-brown text-4xl ml-5">
                {name}
              </h2>
            </div>
            <div className="text-custom-teal-body">
              Combining the ease of installation afforded by interlocking grid
              systems with the manufactured accuracy and durability of press formed
              tiles, the Supersil tegular System provides an economic solution to
              many ceiling requirements. The system creates a modular arrangement
              with the tiles laying into the chosen grid, access to services is
              gained by simply lifting the tiles into the void space. A number of
              acoustic requirements can be met with the introduction of an acoustic
              inlay in the back of a perforated tile.
            </div>
            <div className="text-custom-teal">
              <h2 className="uppercase text-2xl">downloads</h2>
              <div className="grid grid-cols-3">
                <div className="flex gap-3 hover: cursor-pointer">
                  cdr file
                  <svg
                    className="h-5 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </div>
                <div className="flex gap-3 hover: cursor-pointer">
                  catalog
                  <svg
                    className="h-5 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </div>
                <div className="flex gap-3 hover: cursor-pointer">
                  2d view
                  <svg
                    className="h-5 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="absolute right-1/2 mr-8 mb-4 bottom-0 z-10 hover: cursor-pointer">
              <StaticImage
                src="../../assets/images/home/leftArrow.svg"
                quality={95}
                className="w-8 h-8"
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Left Arrow"
              />
              <StaticImage
                src="../../assets/images/home/rightArrow.svg"
                quality={95}
                className="w-8 h-8"
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Right Arrow"
              />
            </div>
          </div>
          <StaticImage
            style={{ width: "objectFit", height: "objectFit" }}
            className="-ml-16"
            src="./Grid_Detail 2.tif"
            formats={["auto", "webp", "avif", "png"]}
            alt="Product Image"
          />
        </div>

        {/* Tabs */}
        <div className={Style.tabContainer}>
          <div className="w-full">
            <ul role="tablist" className={Style.tabList}>
              <li>
                <a
                  className={openTab === 1 ? selectedTabBackground : tabBackground}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist">
                  <i></i> material/perforation
                </a>
              </li>
              <li>
                <a
                  className={openTab === 2 ? selectedTabBackground : tabBackground}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist">
                  <i></i> product specification
                </a>
              </li>
              <li>
                <a
                  className={openTab === 3 ? selectedTabBackground : tabBackground}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist">
                  <i></i> system overview
                </a>
              </li>
              <li>
                <a
                  className={openTab === 4 ? selectedTabBackground : tabBackground}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(4);
                  }}
                  data-toggle="tab"
                  href="#link4"
                  role="tablist">
                  <i></i> download
                </a>
              </li>
            </ul>
            <div className={Style.tabContent}>
              <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                <p>Content-1</p>
              </div>
              <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                <div className="flex justify-center">
                  <div className="w-1/3">
                    <div className="border border-custom-teal uppercase text-center py-3 text-custom-teal font-LatoBold">
                      size
                    </div>
                    <table className="border-custom-teal">
                      <tbody className="font-LatoRegular">
                        {[
                          [575, 575],
                          [585, 585],
                          [1175, 275],
                          [1175, 575]
                        ].map((row, index) => (
                          <tr key={index}>
                            <td>{row[0]}</td>
                            <td>{row[1]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                <p>Content-3</p>
              </div>
              <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                <p>Content-4</p>
              </div>
            </div>
          </div>
        </div>

        {/* Similar Products */}
        <div className="text-center text-custom-teal uppercase pb-16">
          <h1 className="text-center font-OswaldLight py-16">similar products</h1>
          <div className="flex flex-row flex-wrap justify-center gap-28">
            {[...Array(2)].map((index) => (
              <div key={index}>
                <StaticImage
                  style={{ width: "150px", height: "150px" }}
                  src="./Grid_Detail 2.tif"
                  formats={["auto", "webp", "avif", "png"]}
                  alt="Product Image"
                />
                <h4 className="text-center text-xl pt-5 mb-0">Title</h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
