import React, { Component } from "react";
import Products from "../../components/Products";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import Seo from "../../components/seo";

class product extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { name } = this.props.params;
    return (
      <>
        <Seo title={name} />
        <Navbar />
        <Products name={name} />
        <Footer />
      </>
    );
  }
}

export default product;
