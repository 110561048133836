import React from "react";
import DesktopProd from "./desktop";
import MobileProd from "./mobile";

const Products = ({ name }) => {
  return (
    <div>
      <DesktopProd name={name} />
      <MobileProd />
    </div>
  );
};

export default Products;
