import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import BreadCrumb from "../BreadCrumb";
import * as Style from "./products.module.css";

const Mobile = () => {
  const [activeAccordion, setActiveAccordion] = useState(2);
  return (
    <div className="lg:hidden bg-heading-color">
      <BreadCrumb className="text-xs absolute top-32 left-[7.5%]">
        home/products/celing tile lay-in
      </BreadCrumb>
      <div className={`${Style.main} ${Style.bgProp}`}>
        <div
          className="container m-auto"
          style={{
            width: "85%"
          }}>
          <div className="flex w-3/4 py-4">
            <svg
              className="h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                color="#E0B65F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <h1
              className="font-OswaldMedium text-custom-brown mb-4 uppercase w-2/3 pl-6"
              style={{
                fontSize: "7vw"
              }}>
              ceiling tile lay-in
            </h1>
          </div>

          {/* Carousel */}
          <div className="flex justify-end relative ml-4">
            <StaticImage
              style={{ width: "objectFit", height: "45vh" }}
              className="ml-9"
              src="./Grid_Detail 2.tif"
              formats={["auto", "webp", "avif", "png"]}
              alt="Product Image"
            />
            <div className="absolute left-0 bottom-0 z-10 hover: cursor-pointer">
              <StaticImage
                src="../../assets/images/home/leftArrow.svg"
                quality={95}
                className="w-9 h-9"
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Left Arrow"
              />
              <StaticImage
                src="../../assets/images/home/rightArrow.svg"
                quality={95}
                className="w-9 h-9"
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Right Arrow"
              />
            </div>
          </div>

          {/* Product Description */}
          <div className="text-custom-teal-body py-16 pl-4 px-1 font-sans">
            Combining the ease of installation afforded by interlocking grid systems
            with the manufactured accuracy and durability of press formed tiles, the
            Supersil tegular System provides an economic solution to many ceiling
            requirements. The system creates a modular arrangement with the tiles
            laying into the chosen grid, access to services is gained by simply
            lifting the tiles into the void space. A number of acoustic requirements
            can be met with the introduction of an acoustic inlay in the back of a
            perforated tile.
          </div>

          {/* Actions */}
          <div className="text-custom-teal px-1">
            <h2 className="uppercase text-xl mb-2 font-LatoRegular">downloads</h2>
            <div className="grid grid-cols-3">
              <div className="flex gap-1 hover: cursor-pointer text-lg">
                cdr file
                <svg
                  className="h-5 w-6 mt-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
              </div>
              <div className="flex gap-1 hover: cursor-pointer text-lg">
                catalog
                <svg
                  className="h-5 w-6 mt-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
              </div>
              <div className="flex gap-1 hover: cursor-pointer text-lg">
                2d view
                <svg
                  className="h-5 w-6 mt-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* Accordion */}
          <div className="mt-20 mx-1 bg-[#f4eee4ff]">
            <button
              onClick={() => setActiveAccordion(1)}
              className={`${Style.accordion} flex relative`}>
              material/perforation
              <svg
                className="h-6 w-6 absolute right-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`${Style.panel} ${
                activeAccordion === 1
                  ? "visible scale-y-100 pt-[2.5rem] pb-[1.25rem] px-[1rem]"
                  : "invisible scale-y-0 p-0 w-0 h-0"
              }`}>
              <p>Content-1</p>
            </div>
            <button
              onClick={() => setActiveAccordion(2)}
              className={`${Style.accordion} flex relative`}>
              product specification
              <svg
                className="h-6 w-6 absolute right-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`${Style.panel} ${
                activeAccordion === 2
                  ? "visible scale-y-100 pt-[2.5rem] pb-[1.25rem] px-[1rem]"
                  : "invisible scale-y-0 p-0 w-0 h-0"
              }`}>
              <div className="flex justify-center">
                <div className="w-full px-11">
                  <div className="border border-custom-teal uppercase text-center py-3 text-custom-teal font-LatoBold">
                    size
                  </div>
                  <table className="border-custom-teal">
                    <tbody className="font-LatoRegular">
                      {[
                        [575, 575],
                        [585, 585],
                        [1175, 275],
                        [1175, 575]
                      ].map((row, index) => (
                        <tr key={index}>
                          <td>{row[0]}</td>
                          <td>{row[1]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <button
              onClick={() => setActiveAccordion(3)}
              className={`${Style.accordion} flex relative`}>
              system overview
              <svg
                className="h-6 w-6 absolute right-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`${Style.panel} ${
                activeAccordion === 3
                  ? "visible scale-y-100 pt-[2.5rem] pb-[1.25rem] px-[1rem]"
                  : "invisible scale-y-0 p-0 w-0 h-0"
              }`}>
              <p>Content-3</p>
            </div>
            <button
              onClick={() => setActiveAccordion(4)}
              className={`${Style.accordion} flex relative`}>
              downloads
              <svg
                className="h-6 w-6 absolute right-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`${Style.panel} ${
                activeAccordion === 4
                  ? "visible scale-y-100 pt-[2.5rem] pb-[1.25rem] px-[1rem]"
                  : "invisible scale-y-0 p-0 w-0 h-0"
              }`}>
              <p>Content-4</p>
            </div>
          </div>

          {/* Similar Products */}
          <div className="text-center text-custom-teal uppercase py-10">
            <h1 className="text-center font-OswaldLight py-16">similar products</h1>
            <div className="flex flex-row flex-wrap justify-center gap-24">
              {[...Array(2)].map((index) => (
                <div key={index}>
                  <StaticImage
                    style={{ width: "150px", height: "150px" }}
                    src="./Grid_Detail 2.tif"
                    formats={["auto", "webp", "avif", "png"]}
                    alt="Product Image"
                  />
                  <h4 className="text-center text-xl pt-5 mb-0">Title</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
